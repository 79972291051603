import React, { useState, useEffect } from 'react';
import Layout from '../components/Layout/Layout';
import { Container, Row, Col, Button, Carousel, Card } from 'react-bootstrap';
import "../styles/HomePage.css";

const HomePage = () => {
  const [userCount, setUserCount] = useState(null);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 3; // Show 4 users per page
  const [isModalOpen, setModalOpen] = useState(false);


  // Reverse the users array so that the most recent users come first
  const reversedUsers = [...users].reverse();

  // Calculate the total number of pages
  const totalPages = Math.ceil(reversedUsers.length / usersPerPage);

  // Get the users for the current page
  const currentUsers = reversedUsers.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  // Go to the next page
  const handleNext = () => {
    if (currentPage < totalPages - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Go to the previous page
  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  useEffect(() => {
    const fetchUserCount = async () => {
      try {
        const response = await fetch('https://start-keo4.onrender.com/api/user-count'); // Update the URL if necessary
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.json();
        setUserCount(data.count);
      } catch (error) {
        console.error('Error fetching user count:', error);
      }
    };
  
    fetchUserCount();
  }, []);



  useEffect(() => {
    fetch('https://start-keo4.onrender.com/api/users')
      .then(res => res.json())
      .then(data => {
        console.log(data); // Log users data to verify
        setUsers(data);
      });
  }, []);

  const topUsers = React.useMemo(() => {
    return (users || [])
      .sort((a, b) => b.referenceCount - a.referenceCount) // Sort by referenceCount in descending order
      .slice(0, 3); // Take top 3 users
  }, [users]);

  const handleButtonClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  }



  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://start-keo4.onrender.com/api/v1/users');
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${response.statusText}`);
        }
        const data = await response.json();
        console.log(data); // For debugging
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);


  return (
    <Layout>
<header className="hero-section">
        <Carousel
          nextIcon={<span aria-hidden="true" className="carousel-control-next-icon" />}
          prevIcon={<span aria-hidden="true" className="carousel-control-prev-icon" />}
        >
          <Carousel.Item>
            <div
              className="hero-slide"
              style={{
                backgroundImage:
                  "url('https://media.istockphoto.com/id/1465188429/photo/business-performance-monitoring-concept-businessman-using-smartphone-online-survey-filling.jpg?s=612x612&w=0&k=20&c=7c47U-ZeTVL4H1_jPyO-8V3mKVPbeDb25oOxIV7NoEE=')",
              }}
            > 
              <Container>
                <Row className="align-items-center text-center text-md-left">
                  <Col md={6}>
                    <h1>Welcome to Our</h1>
                    <h1>Digital Business Plan</h1>
                    <p>ડિજિટલ બિઝનેસ પ્લાન માં 1100 રૂપિયા ઈન્વેસ્ટ કરીને તમારા સપના સાકાર કરો.</p>
                    <Button variant="primary" size="lg">Get Started</Button>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div
              className="hero-slide"
              style={{ backgroundImage: "url('https://static.vecteezy.com/system/resources/thumbnails/006/655/084/small/business-development-planning-inscription-on-3d-the-virtual-screen-photo.jpg')" }}
            >
              <Container>
                <Row className="align-items-center text-center text-md-left">
                  <Col md={6}>
                    <h1>DIGITAL BUSINESS GROUP</h1>
                    <p>બિઝનેસ એક વ્યક્તિ દ્વારા સફળ નથી થતો, એક ટીમ દ્વારા સફળ થાય છે.</p>
                    {/* <Button variant="primary" size="lg">Get Started</Button> */}
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div
              className="hero-slide"
              style={{ backgroundImage: "url('https://img.freepik.com/premium-photo/business-development-success-growth-banking-financial-global-network_34200-516.jpg')" }}
            >
              <Container>
                <Row className="align-items-center text-center text-md-left">
                  <Col md={6}>
                    <h1>Digital Business Plan</h1>
                    <p>DIGITAL BUSINESS GROUP નો એવો હેતું છે કે આપણે બધા એકસાથે મળી ને આગળ વધીએ.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div
              className="hero-slide"
              style={{ backgroundImage: "url('https://img.freepik.com/premium-photo/business-development-success-growth-banking-financial-global-network_34200-516.jpg')" }}
            >
              <Container>
                <Row className="align-items-center text-center text-md-left">
                  <Col md={6}>
                    <h1>Digital Business Plan</h1>
                    <p>DBP(DIGITAL BUSINESSPLAN) માં નાના માં નાનું ઇન્વેસ્ટમેન્ટ કરી ને ઓન્લી રેફરન્સથી વધુમાં વધુ ઇન્કમ જનરેટ કરો.</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>

          <Carousel.Item>
            <div
              className="hero-slide"
              style={{ backgroundImage: "url('https://static.vecteezy.com/system/resources/thumbnails/006/655/084/small/business-development-planning-inscription-on-3d-the-virtual-screen-photo.jpg')" }}
            >
              <Container>
                <Row className="align-items-center text-center text-md-left">
                  <Col md={6}>
                    <h1>Digital Business Plan</h1>
                    <p>એક વાત યાદ રાખો કે પૈસા "માનવીનું સર્જન કરતો નથી, પરંતુ માનવી જ પૈસા નું સર્જન કરે છે..!!</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </Carousel.Item>
        </Carousel>
</header>

<section className="user-count-section py-5">
  <div className="home-container" style={{ position: 'relative' }}>
    <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>Top Registered Users</h2>
    <div className="user-cards" style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', gap: '20px' }}>
      {topUsers.length > 0 ? topUsers.map((user, index) => (
        user.role !== 1 && ( // Check if the role is not equal to 1 (exclude admins)
          <div
            key={user._id} // Use a unique identifier if available
            className="user-card"
            style={{
              display: 'inline-block',
              padding: '20px',
              borderRadius: '15px',
              boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
              background: '#fff',
              transform: 'scale(1.02)',
              transition: 'transform 0.3s',
              textAlign: 'center',
              minWidth: '200px'
            }}
          >
            <div className="user-info">
              {user?.photo ? (
                <img
                src={`https://start-keo4.onrender.com/public/Images/${user?.photo.split('/').pop()}`}
                  alt="User"
                  className="img-fluid"
                  style={{
                    width: "120px",
                    height: "120px",
                    borderRadius: "50%",
                    border: "4px solid #007bff",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                    objectFit: "cover",
                  }}
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/image/Aboutt.jpg'; // Fallback image if the user image is not found
                  }}
                />
              ) : <div></div>}
              <h3 style={{ fontSize: '18px', marginBottom: '10px' }}>{user.firstname} {user.lastname}</h3>
              <p style={{ fontSize: '14px', marginBottom: '10px' }}>Users Registered with {user.referenceCode}:</p>

              <div className="circle" style={{
                width: '60px',
                height: '60px',
                borderRadius: '50%',
                background: '#4a90e2',
                color: '#fff',
                textAlign: 'center',
                lineHeight: '60px',
                boxShadow: '0 6px 12px rgba(0, 0, 0, 0.3)',
                fontSize: '20px',
                fontWeight: 'bold',
                margin: 'auto'
              }}>
                {user.referenceCount}
              </div>
            </div>
          </div>
        )
      )) : (
        <p>No users available</p>
      )}
    </div>
  </div>
</section>


<section className="features-section py-5">
      <Container>
        <div className="d-flex align-items-center justify-content-between">
          {/* Left Content */}
          <div className="left-content" style={{ flex: '1', textAlign: 'center' }}>
            <div className="info-box" style={{ background: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <h3>Join Our Community</h3>
              <p>Explore the advantages:</p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px', textAlign: 'left' }}>
                <li>Exclusive offers and discounts</li>
                <li>Access to premium content</li>
                <li>Regular updates and news</li>
              </ul>
            </div>
          </div>

          {/* Center Button */}
          <div className="text-center" style={{ flex: '0 0 auto' }}>
            <Button 
              variant="primary" 
              onClick={handleButtonClick}
              className="toggle-button"
              style={{ margin: '20px' }} 
            >
              કેવી રીતે જોડાવું ?
            </Button>

            <div className={`modal-overlay ${isModalOpen ? 'active' : ''}`}>
              <div className="modal-content">
                <h4>Details:</h4>
                <p>અમારી વેબસાઇટ DIGITALBUSINESSPLAN.IN પર જાઓ અને ફોર્મ ભરો.</p>
                <p>અમારા સુરક્ષિત પેમેન્ટ ગેટવે દ્વારા ₹1100 ની જોડાવાની ફી ચૂકવો</p>
                <p>પેમેન્ટ કન્ફર્મ થાય પછી, તમને તમારું લોગિન વિગત અને આગળના નિર્દેશો સાથે એક ઇ-મેઇલ મળશે</p>
                <Button variant="secondary" onClick={handleCloseModal} style={{ marginTop: '10px' }}>Close</Button>
              </div>
            </div>
          </div>

          {/* Right Content */}
          <div className="right-content" style={{ flex: '1', textAlign: 'center' }}>
            <div className="info-box" style={{ background: '#f9f9f9', padding: '20px', borderRadius: '8px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' }}>
              <h3>Why Join Us?</h3>
              <p>Benefits of becoming a member:</p>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px', textAlign: 'left' }}>
                <li>Get exclusive access to special content</li>
                <li>Receive updates and newsletters</li>
                <li>Enjoy member-only offers and promotions</li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
</section>

<section className="features-section py-5">
  <Container>
    <div className="home-container" style={{ position: 'relative' }}>
      <h2 style={{ textAlign: 'center', marginBottom: '20px' }}>
        Last Registered Users
      </h2>
      <div
        className="user-cards"
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          gap: '20px',
          justifyContent: 'center',
        }}
      >
        {currentUsers.length > 0 ? (
          currentUsers.map((user) => (
            <div
              key={user._id}
              className="user-card"
              style={{
                padding: '20px',
                borderRadius: '15px',
                boxShadow: '0 10px 20px rgba(0, 0, 0, 0.2)',
                background: '#fff',
                transform: 'scale(1.02)',
                transition: 'transform 0.3s',
                textAlign: 'center',
                minWidth: '200px',
              }}
            >
              <div className="text-center mb-4">
                {user?.photo ? (
                  <img
                    src={`https://start-keo4.onrender.com/public/Images/${user?.photo.split('/').pop()}`}
                    alt="User"
                    className="img-fluid"
                    style={{
                      width: "120px",
                      height: "120px",
                      borderRadius: "50%",
                      border: "4px solid #007bff",
                      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                      objectFit: "cover",
                    }}
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = '/image/Aboutt.jpg'; // Fallback image if the user image is not found
                    }}
                  />
                ) : (
                  <div className="text-muted">Photo</div>
                )}

                <h3 style={{ fontSize: '18px', marginBottom: '15px' }}>
                  {user.firstname} {user.lastname} 
                </h3>
                <p style={{ fontSize: '15px', marginBottom: '10px' }}>
                  ReferenceCode: {user.referenceCode}
                </p>
              </div>
            </div>
          ))
        ) : (
          <p>No users available</p>
        )}
      </div>

      {/* Navigation buttons */}
      <div style={{ marginTop: '20px', textAlign: 'center' }}>
        <button
          onClick={handlePrevious}
          disabled={currentPage === 0}
          style={{
            marginRight: '10px',
            padding: '10px 20px',
            fontSize: '16px',
            cursor: currentPage === 0 ? 'not-allowed' : 'pointer',
          }}
        >
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={currentPage === totalPages - 1}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            cursor: currentPage === totalPages - 1 ? 'not-allowed' : 'pointer',
          }}
        >
          Next
        </button>
      </div>
    </div>
  </Container>
</section>



<section className="user-count-section py-5">
<div className="plana-item">
            <p className="item-descriptionna">
            ડિજિટલ બિઝનેસ પ્લાન માં આઉટ ઓફ ગુજરાત માંથી ઓનલાઈન પ્લાન લઇ શકશે. આ પ્લાન માં જોડાવા માટે તમારે તમારું ઈ-મેલ આઈડી મોબાઈલ નંબર અને આધારકાર્ડ અને પાસપોર્ટ સાઈઝ ફોટો એટલા ડોક્યુમેન્ટ થી  જોઈનીંગ કરશો. પેમેન્ટ ફરજીયાત @1100/- ઓનલાઈન કરવાનું રેહશે અને એમાં ઓન્લી રેફેરેંસ કોડ નાખવો ફરજીયાત રહેશે.તેજ માન્ય ગણાશે.અને આમાંથી રેફેરેંસ થી જ ઈન્ક્મ જનરેટ થશે.તેની નોંધ લેવી. 
            તમે જોબ કરતા કરતા પણ ઈનકમ કમાઈ શકશો.ઘરે બેઠા બેઠા પણ ઇનકમ કમાઈ શકશો. બિઝનેસ કરતા કરતા પણ ઇનકમ કમાઈ શકશો.
            </p>
          </div>
</section>

<section className="user-count-section py-5">
  <Container>
    <Row className="justify-content-center">
      <Col md={6}>
        <Card className="text-center p-4 shadow-lg rounded-4 border-0" style={{
          background: 'linear-gradient(145deg, #e6e6e6, #ffffff)',
          boxShadow: '20px 20px 50px #b3b3b3, -20px -20px 50px #ffffff',
          transition: 'transform 0.3s ease-in-out',
          maxWidth: '400px',
          margin: '0 auto'
        }} 
        onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.03)'}
        onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
        >
          <Card.Body>
            <Card.Title className="mb-3" style={{
              fontFamily: "'Roboto', sans-serif",
              fontSize: '1.5rem',
              fontWeight: '600',
              color: '#4a4a4a',
              textShadow: '1px 1px 3px rgba(0, 0, 0, 0.2)'
            }}>
              Total Users
            </Card.Title>
            <div className="circle-wrapper mx-auto" style={{
              width: '120px',
              height: '120px',
              borderRadius: '50%',
              backgroundColor: '#f0f0f0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              boxShadow: 'inset 8px 8px 15px #bfbfbf, inset -8px -8px 15px #ffffff',
              cursor: 'pointer',
              transition: 'transform 0.3s ease-in-out'
            }} 
            onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
            >
              <h2 style={{ 
                fontSize: '2.5rem', 
                fontWeight: 'bold', 
                color: '#6a6a6a',
                textShadow: '1px 1px 3px rgba(0, 0, 0, 0.2)',
                animation: 'pulse 1.5s infinite'
              }}>
                {userCount !== null ? userCount : 'Loading...'}
              </h2>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  </Container>
</section>

<style jsx>{`
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`}</style>

    </Layout>
  );
}



export default HomePage;
